/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"

import Header from "./header"
import "./layout.css"
import "../assets/css/style.css"
import "../assets/css/floating-labels.css"
import Chat from "./Chat";
import loaderImg from "../images/icon-7.png"
import SucessPage from "./success";
import OppsPage from "./opps";

const Layout = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    }
  }

  menuToggle = (e) => {
    this.setState({isMenuOpen: e});
  }

  render() {
    const { isMenuOpen } = this.state;
    const { children, layoutType, pageLoader, isShowSuccessPopup, isShowErrorPopup, rest } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            {pageLoader &&
              <div className="loader-bg">
                <div className="loader-inner">
                  {/* <div className="loader">Loading...</div> */}
                  <img className="pagemyloader" src={loaderImg} alt="" />
                </div>
              </div>
            }
            {
              isShowSuccessPopup &&
              <SucessPage />
            }
            {
              isShowErrorPopup &&
              <OppsPage />
            }
            {/* <Header 
            rest={rest} 
            layoutType={layoutType}
            menuToggle={(e) => this.menuToggle(e)} /> */}
            <main className={isMenuOpen ? 'menuOpenClass' : 'menuCloseClass'}>{children}</main>
            {/* <Chat /> */}
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    isShowSuccessPopup: state.primary.isShowSuccessPopup,
    isShowErrorPopup: state.primary.isShowErrorPopup,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    // onAddCounter: () => dispatch(actionCreators.add(10)),
    // onSubtractCounter: () => dispatch(actionCreators.subtract(15)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
