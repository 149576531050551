import React from 'react';
import { connect } from 'react-redux';
import * as actionCreators from './../store/actions';

class ErrorHandel extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.self.refs[`${nextProps.formName}`]) {
      if (!nextProps.isShowErrors) {
        if (nextProps.serverErrors && nextProps.serverErrors.length) {
          this.props.showServerError();
          nextProps.serverErrors.forEach((err) => {
            if (err && err.data) {
              Object.keys(err.data).forEach(key => {
                nextProps.self.refs[`${nextProps.formName}`].updateInputsWithError({
                  [`${key}`]: err.data[key].replace (/"/g, ""),
                });
              })
            }
          });
        }
      }
    }
  }

  render() {
    return null;
  }
}


const mapStateToProps = state => {
  return {
    serverErrors: state.primary.serverErrors,
    isShowErrors: state.primary.isServerErrorsShow
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showServerError: () => dispatch(actionCreators.showServerError()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandel);
