import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"

import "./header.css"
import Logo from "../images/logo.png"
import { getCurrentPath } from "../core/util";
import * as actionCreators from './../store/actions';

class Header extends Component {
  state = {
    loading: false,
    isMobileMenuopen: false,
    isLogout: false,
    dropdownMenu: false,
    dealertoken:''
  }
  componentDidMount = () => {
    
  }

  doLogout(isHideNavbar) {
    this.setState({ isLogout: true });
    if (isHideNavbar) {
      this.setState({ isMobileMenuopen: false });
      this.props.menuToggle(false);
    }

    if (this.props && this.props.rest && this.props.rest.location && this.props.rest.location.pathname === "/") {
      this.props.logout();
    } else {
      navigate("/", {
        state: { isLogout: true },
      })
    }
  }

  doRefresh() {
    if (window.location.pathname.includes('agreement')) {
      sessionStorage.clear();
      this.props.clearAllSteps();
      navigate("/reload2")
    } else {
      
      this.props.clearAllSteps();
      navigate("/reload")
    }
  }

  registerActiveClass = () => {
    let currentPath = getCurrentPath();
    if ((currentPath.includes('vehicleInfo') ||
      currentPath.includes('yourInfo') ||
      currentPath.includes('customizePlan') ||
      currentPath.includes('createAccount') ||
      currentPath.includes('securePayment') ||
      currentPath.includes('confirm'))) {
      return 'active';
    } else {
      return '';
    }
  }

  render() {
    const { layoutType} = this.props;
    
    
    return (
      <header id="pageHeader">
        {/* --------main header------ */}
        {
          layoutType === 'main' &&
          <div className="inner-header">
            <div className="header-content">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
              {/* -------- first page menu--------- */}
            </div>

            {/* -------- first page menu--------- */}
          </div>
        }
      </header >
    )
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => {
  return {
  }
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
    clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

