import React, { Component } from "react"
import "../assets/css/success.css"
import { connect } from "react-redux"
import * as actionCreators from './../store/actions';

import closeChat from "../images/close-icon.svg"
import sucessimage from "../images/success.img.svg"
import { navigate } from "gatsby";

class SucessPage extends Component {

    closePopup = () => {
        const { hideSuccessPopup, redirectOnSuccess } = this.props;
        hideSuccessPopup();
        if(redirectOnSuccess) {
            navigate(`${redirectOnSuccess}`)
        }
    }

    render() {
        const { popupMessage, btnLabel } = this.props;
        return (
            <div className="success-container">
                <div className="success-inner">
                    <div onClick={() => this.closePopup()} className="close-btn close">
                        <img className="" src={closeChat} alt="" />
                    </div>

                    <div className="content">
                        <div className="text">
                            <p>Success</p>
                        </div>
                        <div className="sub-text">
                            <p>{popupMessage}</p>
                            {/* <p>Your has been successfully done!</p> */}
                        </div>
                        <div className="banner-image">
                            <img className="" src={sucessimage} alt="" />
                        </div>
                        <div onClick={() => this.closePopup()} className="button">
                            <button className="btn">{btnLabel ? btnLabel : 'Ok'}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        popupMessage: state.primary.popupMessage,
        redirectOnSuccess: state.primary.redirectOnSuccess,
        btnLabel: state.primary.btnLabel
    }
};

const mapDispatchToProps = dispatch => {
    return {
        hideSuccessPopup: () => dispatch(actionCreators.hideSuccessPopup()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SucessPage);
