import React, { Component } from "react"
import "../assets/css/opps.css"
import { connect } from "react-redux"
import * as actionCreators from './../store/actions';

import closeChat from "../images/close-icon.svg"
import oppsimage from "../images/opps.img.svg"

class OppsPage extends Component {
    render() {
        const { popupMessage, hideErrorPopup } = this.props;
        return (
            <div className="opps-container">
                <div className="opps-inner">
                    <div onClick={() => hideErrorPopup()} className="close-btn">
                        <img className="" src={closeChat} alt="" />
                    </div>

                    <div className="content">
                        <div className="text">
                            <p>Oops!</p>
                        </div>
                        <div className="sub-text">
                            {/* <p>404-The Page You Requested Could Not Be Found.</p> */}
                            <p>{popupMessage}</p>
                        </div>
                        <div className="banner-image">
                            <img className="" src={oppsimage} alt="" />
                        </div>
                        <div onClick={() => hideErrorPopup()} className="button">
                            <button className="btn">Go To Back</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        popupMessage: state.primary.popupMessage,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        hideErrorPopup: () => dispatch(actionCreators.hideErrorPopup()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OppsPage);

