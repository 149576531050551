import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';

class MyInput extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   blurInput: false
    // }
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    this.props.setValue(event.currentTarget.value);
    if (this.props.valueChanges) {
      this.props.valueChanges(event.currentTarget.value)
    }
  }
  


  render() {
    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const { id, name, className, placeholder, type, disabled, errorClassName,hint } = this.props;

    return (
      <>
        {/* {isLabelRequired &&
          <label htmlFor={id}>{placeholder}{required ? '*' : ''}</label>
        } */}
        <input
          onChange={this.changeValue}
          onBlur={this.blurValue}
          id={id}
          name={name}
          // onBlur={() => this.setState({ blurInput: true })}
          className={className}
          type={type ? type : 'text'}
          value={this.props.getValue() || ''}
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
        />
        
        {!this.props.getValue()?
        <small className="error-text">{hint?hint:''}</small>
        :
        <small className={`error-text ${errorClassName ? errorClassName : ''}`}>{errorMessage?errorMessage:''}</small>
        }
      </>
    );
  }
}

export default withFormsy(MyInput);