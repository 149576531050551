
import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';

class MyCheckbox extends Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.checked);
    if (this.props.checkBoxChange) {
      this.props.checkBoxChange(event.currentTarget.checked);
    }
  }

  render() {
    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const { id, name, labelClass, label,disabled,title, link } = this.props;
    // const { id, name, className, labelClass, label } = this.props;
    
    return (
      <>
        <label className={title?labelClass+' hastt':labelClass} htmlFor={id} key={'inp'+id}>{label}
        {link && <a href="https://servicecontract.com/terms" target="_blank"> SERVICE AGREEMENT</a>}
          <input type="checkbox" name={name} id={id} disabled={disabled}
            checked={this.props.getValue() || ''}
            onChange={this.changeValue}
            key={'inp'+id}
          />
          <span key={'sp'+id} className="checkmark"></span>
        </label>
        <small key={'sm'+id} className="error-text">{errorMessage}</small>
      </>
    );
  }
}

export default withFormsy(MyCheckbox);